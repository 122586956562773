<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>الإدارة العامة للتدريب</h3></div>

    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h4 class="blue--text">
          شركه مياه الشرب والصرف الصحي بأسيوط و الوادي الجديد
        </h4>
        <h4>/ الإدارة العامة للتدريب</h4></v-row
      >
      <v-row class="container mt-10 justify-center">
        <v-carousel
          cycle
          width="100%"
          height="30em"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <div v-for="(item, i) in itemsImage" :key="i" class="fade-in">
            <v-carousel-item
              class="fade-in white--text"
              v-bind:style="{
                'background-image': 'url(' + item.src + ') ',
                'background-size': '100% 100%',
              }"
            >
            </v-carousel-item>
          </div>
        </v-carousel>
      </v-row>
      <hr class="ma-5 hrClass" />
      <v-card color="basil">
        <v-card-title class="text-center justify-center py-6">
          <h3 class="font-weight-bold text-h4 basil--text">
            الإداره العامه للتدريب
          </h3>
        </v-card-title>

        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
          <v-tab class="titleCard" v-for="item in itemsTraning" :key="item">
            {{ item.title }}
            <v-icon>{{ item.icon }}</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in itemsTraning" :key="item">
            <v-card color="basil" flat>
              <v-card-text class="pa-10">
                <ul>
                  <li
                    v-for="i in item.subTitle"
                    :key="i"
                    class="justify-center mr-10 font-weight-bold liCard"
                  >
                    {{ i }}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <hr class="ma-5 hrClass" />
      <h2 class="font-weight-bold subTitle">أنواع البرامج التدريبية</h2>
      <v-expansion-panels v-model="panel" multiple class="mt-5">
        <v-expansion-panel class="changeTitleStyleTrain">
          <v-expansion-panel-title
            expand-icon="mdi-menu-down"
            class="container"
          >
            <h4>
              <ul>
                <li class="mr-10">
                  حصول التدريب علي الايزو
                </li>
              </ul>
            </h4>
          </v-expansion-panel-title>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row class="mt-5 justify-center">
        <v-col
          v-for="(dTraning, i) in Department"
          :key="i"
          xs="12"
          sm="4"
          md="4"
          lg="4"
          cols="12"
        >
          <router-link :to="dTraning.link" class="departmentTraning">
            <v-img
              contain
              class="Labimg "
              :lazy-src="loadingLink"
              :src="dTraning.img"
              width="350px"
              height="250px"
            >
            </v-img>
            <h4
              class="text-center pa-5 font-weight-bold departmentTraning"
              style="'text-decoration':'none'"
            >
              {{ dTraning.title }}
            </h4>
          </router-link>
        </v-col></v-row
      >
      <hr class="ma-5 hrClass" />
      <v-flex> <h2 class="font-weight-bold subTitle">القاعات</h2></v-flex>
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel class="changeTitleStyleTrain">
          <v-expansion-panel-title
            expand-icon="mdi-menu-down"
            class="container"
          >
            <h4>
              <ul>
                <li class="mr-10">
                  يوجد لدينا قاعات تدريب بشركه مياه أسيوط والصرف الصحي متاح
                  الاستعلام والحجز من أي جهه داخل أو خارج الشركة
                </li>
              </ul>
            </h4>
          </v-expansion-panel-title>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-row v-for="item in ImagesLab" :key="item" class="mt-10">
        <v-col cols="12" lg="4" sm="4" md="4">
          <expandable-image
            class="Labimg scale-in-center mt-5"
            :src="item.img"
          />
          <row><h3 class="contact_us mt-3">أتصل بنا</h3></row>
          <div class="d-flex social_media_color justify-center">
            <v-tooltip bottom v-for="(item, i) in social_media" :key="i">
              <template v-slot:activator="{ on }">
                <a :href="item.link">
                  <img
                    v-if="item.fun"
                    :src="item.srcmedia"
                    class="ml-5"
                    width="30vh"
                    v-on="on"
                    @click="readPhoneNumfun"
                  />
                  <img
                    v-else
                    :src="item.srcmedia"
                    class="ml-5"
                    width="30vh"
                    v-on="on"
                  />
                </a>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="8"
          sm="8"
          md="8"
          v-animate-onscroll="'fade-in-right'"
          ><h3>
            قاعه رقم ( {{ item.numberOfRoom }} ) - <v-icon>mdi-phone</v-icon>
            {{ phoneCurrentNum }}
          </h3>

          <div class="container">
            <h3 class="roomText">{{ item.subText }} مجهزه بأحدث الأجهزه.</h3>
            <ul>
              <li>
                <v-icon>mdi-projector</v-icon> {{ roomOfTraning.machine1 }}
              </li>
              <li>
                <v-icon>mdi-desktop-classic</v-icon>
                {{ roomOfTraning.machine2 }}
              </li>
              <li>
                <v-icon>mdi-microphone</v-icon> {{ roomOfTraning.machine3 }}
              </li>
              <li>
                <v-icon>mdi-camera-outline</v-icon> {{ roomOfTraning.machine4 }}
              </li>
            </ul>
            <h3 class="roomText">أدوات مساعدات تدريبيه</h3>
            <ul>
              <li>
                <v-icon>mdi-clipboard-multiple</v-icon>
                {{ roomOfTraning.tool1 }}
              </li>
              <li>
                <v-icon>mdi-grease-pencil</v-icon> {{ roomOfTraning.tool2 }}
              </li>
              <li>
                <v-icon>mdi-bag-personal</v-icon>{{ roomOfTraning.tool4 }}
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-snackbar v-model="snackbar" :vertical="vertical">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              غلق
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loadingLink: require("@/assets/Images/load.svg"),
      snackbar: false,
      text: "تم نسخ رقم تليفون التدريب أذهب للصق في أي مكان ",
      vertical: true,
      phoneNum: "01280733381",
      phoneCurrentNum: "088 2334346",
      panel: [0],
      tab: null,
      itemsTraning: [
        {
          subTitle: [
            "إعداد الكوادر الإدارية والفنية المتخصصة كل في مجاله ",
            "تنمية الموارد البشرية بمساعدتها على اكتساب وتحسين المهارات والكفاءات للقيام بالواجبات الحالية والمستقبلية",
            " تمكين الشركة من العمل طبقا لمعايير الجودة المطلوبة ",
            "تعزيز فرص النمو والتطور لدى موظفي الشركة من أجل تنمية طاقاتهم",
          ],
          title: "أهدفناالاستراتيجيه",
          icon: "mdi-database",
        },
        {
          subTitle: [
            "الولاء والانتماء . المصداقية . الالتزام وبناء الثقة . الشفافية ",
          ],
          title: "قيمنا",
          icon: "mdi-earth-plus",
        },
        {
          subTitle: [
            "الإشراف الكامل على مختلف صور التدريب",
            "حسن اختيار الكوادر المسئولة عن إدارة منظومة التدريب.",
          ],
          title: "رسالتنا",
          icon: "mdi-camera",
        },
        {
          subTitle: [
            "الوصول إلى مستوى أداء عالمى فى إطار منظومة من القيم السائدة",
          ],
          title: "رؤيتنا",
          icon: "mdi-eye",
        },
      ],
      itemsImage: [
        {
          src: require("@/assets/Images/40.jpeg"),
        },
        {
          src: require("@/assets/Images/41.webp"),
        },
        {
          src: require("@/assets/Images/42.jpg"),
        },
      ],
      ImagesLab: [
        {
          img: require("@/assets/Images/47.jpg"),
          numberOfRoom: "1",
          subText: "لعقد ورش عمل تسع 30 فرد ",
        },
        {
          img: require("@/assets/Images/48.jpg"),
          numberOfRoom: "2",
          subText: "قاعة محاضرات تسع 50 متدريب",
        },
        {
          img: require("@/assets/Images/49.jpg"),
          numberOfRoom: "3",
          subText: "قاعه التعليم الاَلكتروني تسع 20 متدرب",
        },
        {
          img: require("@/assets/Images/50.jpg"),
          numberOfRoom: "4",
          subText: "قاعة إجتماعات (U-SHAPE) تسع 30 متدرب",
        },
      ],
      roomOfTraning: {
        subText: "مجهزه بأحدث الاجهزه ",
        machine1: " شاشة عرض ( Projector ( smart board )) ",
        machine2: "جهاز كبيوتر ( pc ) ",
        machine3: "ميكرفون ( Sound system ) ",
        machine4: " كاميرا ( Video cam )  ",
        tool1: "سبوره بيضاء ( White board )",
        tool2: "قلم سبوره ( penboard )",
        tool3: "",
        tool4: "حقيبه أدوات التدريب ( Training tools bag ) ",
        projector_icon: "mdi-projector",
      },
    };
  },
  methods: {
    readPhoneNumfun() {
      this.snackbar = true;
      navigator.clipboard.writeText(this.phoneNum);
    },
  },
  computed: {
    Department() {
      return this.$store.state.departmentTraning;
    },
    social_media() {
      return this.$store.state.contact_traning;
    },
  },
};
</script>
<style>
h3 {
  font-family: Arial, Helvetica, sans-serif;
}
.changeTitleStyleTrain {
  background: rgba(222, 184, 135, 0.192) !important;
  color: gray !important;
}
.Labimg {
  box-shadow: gray 3px 3px 3px 3px;
  border-image: 5px white;
}
.hrClass {
  color: #d8d8d8;
}
.basil {
  background-color: #fffbe6 !important;
}
.basil--text,
.departmentTraning {
  color: #356859 !important;
  text-decoration: none !important;
  font-family: Arial, Helvetica, sans-serif;
}
.titleCard {
  letter-spacing: normal !important;
  font-weight: bold;
  background: none !important;
  padding: 3px;
}
.liCard {
  letter-spacing: normal;
}
.subTitle {
  color: #356859 !important;
}
.roomText {
  color: #356859;
  margin: 5px !important;
  font-weight: bold;
}
.roomText p {
  color: rgb(70, 69, 69) !important;
  margin: 5px !important;
}
.contact_us {
  color: gray;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .Labimg {
    margin-right: 30px;
    margin-right: 30px;
  }
}
</style>
